import { HTMLInputTypeAttribute } from "react";

interface Props {
  className?: string;
  inputClassName?: string;
  errorClassName?: string;
  label?: string;
  name?: string;
  id?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string | number | string[] | undefined | null;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export default function Input({
  className,
  inputClassName,
  errorClassName,
  label,
  name,
  id,
  type = "text",
  placeholder,
  value = null,
  error,
  onChange,
  required = false,
}: Props) {
  return (
    <div className={className}>
      {label && (
        <label className="block mb-1" htmlFor={id}>
          {label} {required && "*"}
        </label>
      )}
      <input
        className={`w-full h-10 bg-grey rounded-sm border-none leading-none ${
          error ? "ring-1 ring-red-500" : ""
        } ${inputClassName}`}
        type={type}
        placeholder={placeholder}
        value={value || ""}
        id={id}
        name={name}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        required={required}
      />
      {error && (
        <p className={`mt-1 text-red-500 text-sm ${errorClassName}`}>{error}</p>
      )}
    </div>
  );
}

import useWeb from "@/scripts/Hooks/useWeb";
import l from "@/scripts/Utils/locale";
import uri from "@/scripts/Utils/uri";
import { usePage } from "@inertiajs/react";
import { useTranslation } from "react-i18next";
import NavigationLink from "./NavigationLink";

export default function CookieAlert() {
  const { t } = useTranslation();
  const { web } = usePage().props;
  const {
    state: { showCookiesAlert },
    actions: { acceptCookies, declineCookies },
  } = useWeb();

  if (!showCookiesAlert) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-col md:flex-row items-center justify-center gap-5 overflow-hidden bg-black text-white px-4 py-5 text-center z-40">
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-1/2 -left-16 md:left-4 -translate-y-1/2 size-48 text-white/20 -rotate-12"
      >
        <path
          d="M2.33281 13.7664C2.22077 13.7664 2.12412 13.7419 2.04286 13.6927C1.96159 13.6436 1.89838 13.5789 1.85322 13.4985C1.80124 13.4198 1.7782 13.331 1.78411 13.2318C1.79002 13.1327 1.81827 13.0373 1.86886 12.9457L7.54269 3.52053C7.59328 3.42895 7.65953 3.36414 7.74144 3.32611C7.82335 3.28807 7.90989 3.26904 8.00106 3.26904C8.09223 3.26904 8.17862 3.28807 8.26024 3.32611C8.34186 3.36414 8.40797 3.42895 8.45856 3.52053L14.1324 12.9457C14.183 13.0373 14.2112 13.1327 14.2171 13.2318C14.2231 13.331 14.2 13.4198 14.148 13.4985C14.098 13.5777 14.0336 13.6422 13.9548 13.6919C13.8759 13.7416 13.7805 13.7664 13.6684 13.7664H2.33281ZM2.91729 12.8998H13.084L8.00063 4.43311L2.91729 12.8998ZM7.99711 11.9716C8.1298 11.9716 8.24219 11.9267 8.33428 11.8369C8.42636 11.7472 8.47241 11.636 8.47241 11.5033C8.47241 11.3706 8.42753 11.2582 8.33778 11.1661C8.24803 11.074 8.13682 11.028 8.00414 11.028C7.87145 11.028 7.75906 11.0729 7.66698 11.1626C7.57489 11.2524 7.52884 11.3636 7.52884 11.4963C7.52884 11.6289 7.57372 11.7413 7.66348 11.8334C7.75322 11.9255 7.86443 11.9716 7.99711 11.9716ZM7.99734 10.228C8.11919 10.228 8.22241 10.1865 8.30703 10.1034C8.39164 10.0204 8.43394 9.91744 8.43394 9.79468V7.46131C8.43394 7.33853 8.39273 7.23562 8.31031 7.15258C8.22789 7.06952 8.12575 7.02799 8.00391 7.02799C7.88206 7.02799 7.77884 7.06952 7.69423 7.15258C7.60961 7.23562 7.56731 7.33853 7.56731 7.46131V9.79468C7.56731 9.91744 7.60852 10.0204 7.69094 10.1034C7.77336 10.1865 7.8755 10.228 7.99734 10.228Z"
          fill="currentColor"
        />
      </svg>
      <p>
        {t(
          "This website uses cookies to ensure you get the best experience on our website"
        )}
        .{" "}
        {web.privacy_policy && (
          <NavigationLink
            className="underline underline-offset-2"
            href={uri("pages.show", { slug: l(web.privacy_policy.slug) })}
          >
            {t("Read more")}
          </NavigationLink>
        )}
      </p>
      <div className="flex items-center gap-3">
        <button
          className="px-3 py-1 ring-1 ring-white"
          type="button"
          onClick={declineCookies}
        >
          {t("Decline")}
        </button>
        <button
          className="px-3 py-1 bg-white text-black"
          type="button"
          onClick={acceptCookies}
        >
          {t("Accept")}
        </button>
      </div>
    </div>
  );
}

import EnvelopeIcon from "@/icons/envelope.svg";
import FacebookIcon from "@/icons/facebook.svg";
import InstagramIcon from "@/icons/instagram.svg";
import LocationIcon from "@/icons/location.svg";
import PhoneIcon from "@/icons/phone.svg";
import TiktokIcon from "@/icons/tiktok.svg";
import TwitterIcon from "@/icons/twitter.svg";
import WhatsappIcon from "@/icons/whatsapp.svg";
import YoutubeIcon from "@/icons/youtube.svg";
import LogoDigitalizadores from "@/images/logo_faldon.png";
import asset from "@/scripts/Utils/assets";
import l from "@/scripts/Utils/locale";
import uri from "@/scripts/Utils/uri";
import CookieAlert from "@/views/Components/CookieAlert";
import NavigationLink from "@/views/Components/NavigationLink";
import { usePage } from "@inertiajs/react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";

export default function Footer() {
  const { web, navigation } = usePage().props;
  const { t } = useTranslation();

  return (
    <>
      <CookieAlert />
      <Toaster
        richColors
        toastOptions={{
          className: "rounded-sm bg-black text-white",
          actionButtonStyle: {
            display: "inline-block",
            borderRadius: "0",
          },
          classNames: {
            closeButton: "ring-1 ring-lightgrey bg-black text-white",
          },
        }}
        closeButton
      />
      <div className="bg-foreground">
        <img
          src={LogoDigitalizadores}
          className="w-full h-auto mx-auto mb-16 max-w-7xl"
          alt="Logo Digitalizadores"
          loading="lazy"
          width={1280}
          height={85}
        />
      </div>
      <footer className="py-16 bg-background">
        <div className="mx-auto max-w-7xl w-content">
          <div className="grid gap-12 mb-16 md:gap-8 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <img
                className="max-w-[156px] w-full h-auto mx-auto"
                src={asset(web.light_logo)}
                loading="lazy"
                width={156}
                height={156}
                alt={web.title}
              />
            </div>
            <div className="">
              <h2 className="mb-4 text-xl font-bold text-center text-white sm:text-left font-title">
                {t("Contact us")}
              </h2>
              <div className="grid gap-3 mx-auto max-w-60 sm:mx-0">
                <ContactItem
                  data={{
                    icon: <img src={EnvelopeIcon} alt={web.contact.email} />,
                    content: web.contact.email,
                    url: `mailto:${web.contact.email}`,
                  }}
                />
                <ContactItem
                  data={{
                    icon: <img src={PhoneIcon} alt={web.contact.phone} />,
                    content: web.contact.phone,
                    url: `tel:${web.contact.phone}`,
                  }}
                />
                <ContactItem
                  data={{
                    icon: <img src={WhatsappIcon} alt={web.contact.whatsapp} />,
                    content: web.contact.whatsapp,
                    label: "Talk us in WhatsApp",
                    url: web.contact.whatsapp_url,
                  }}
                />
                <ContactItem
                  data={{
                    icon: <img src={LocationIcon} alt={web.contact.address} />,
                    content: web.contact.address,
                    label: "Find us with one click",
                    url: web.contact.map_url,
                  }}
                />
              </div>
            </div>
            <div className="">
              <h2 className="mb-4 text-xl font-bold text-center sm:text-left font-title text-white/75">
                {t("Featured links")}
              </h2>
              <div className="grid grid-cols-2 lg:grid-cols-1 justify-items-center sm:justify-items-start gap-x-4 gap-y-3">
                {navigation?.footer_menu &&
                  navigation?.footer_menu.map((item, index) => (
                    <FooterLink href={l(item.url)} key={l(item.url)}>
                      {l(item.label)}
                    </FooterLink>
                  ))}
              </div>
            </div>
            <div className="">
              <h2 className="mb-4 text-xl font-bold text-center sm:text-left font-title text-white/75">
                {t("Social media")}
              </h2>
              <div className="grid grid-cols-2 gap-3 lg:grid-cols-1 justify-items-center sm:justify-items-start">
                {web.social.instagram && (
                  <SocialLink href={web.social.instagram}>
                    <img src={InstagramIcon} alt="Instagram" />
                    Instagram
                  </SocialLink>
                )}
                {web.social.tiktok && (
                  <SocialLink href={web.social.tiktok}>
                    <img src={TiktokIcon} alt="Tiktok" />
                    TikTok
                  </SocialLink>
                )}
                {web.social.facebook && (
                  <SocialLink href={web.social.facebook}>
                    <img src={FacebookIcon} alt="Facebook" />
                    Facebook
                  </SocialLink>
                )}
                {web.social.twitter && (
                  <SocialLink href={web.social.twitter}>
                    <img src={TwitterIcon} alt="Twitter" />
                    Twitter
                  </SocialLink>
                )}
                {web.social.youtube && (
                  <SocialLink href={web.social.youtube}>
                    <img src={YoutubeIcon} alt="Youtube" />
                    Youtube
                  </SocialLink>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-wrap items-center justify-between text-sm md:flex-row gap-x-4 gap-y-4 text-white/75">
            <p className="flex-1">
              {new Date().getFullYear()} © {web.title}
            </p>
            <div className="flex items-center justify-center order-first w-full gap-8 shrink-0 md:w-auto md:order-none">
              {web.privacy_policy && (
                <FooterLink
                  className="text-center"
                  href={uri("pages.show", { slug: l(web.privacy_policy.slug) })}
                >
                  {t("Privacy Policy")}
                </FooterLink>
              )}
              {web.terms_conditions && (
                <FooterLink
                  className="text-center"
                  href={uri("pages.show", {
                    slug: l(web.terms_conditions.slug),
                  })}
                >
                  {t("Terms and Conditions")}
                </FooterLink>
              )}
              <FooterLink
                className="text-center"
                href={uri("pages.show", {
                  slug: l({
                    en: "accessibility-statement",
                    es: "declaracion-de-accesibilidad",
                  }),
                })}
              >
                {t("Accessibility Statement")}
              </FooterLink>
            </div>
            {/* <p className="flex-1 text-right">
              Powered by{" "}
              <a
                className="underline transition-colors duration-150 text-white/70 underline-offset-2 hover:text-white"
                href="https://isabellatech.com"
              >
                IsabellaTech
              </a>
            </p> */}
          </div>
        </div>
      </footer>
    </>
  );
}

function ContactItem({
  data,
}: {
  data: {
    icon?: JSX.Element | null;
    content: string;
    label?: string;
    url: string;
  };
}) {
  const { icon, label, content, url } = data || {};
  return (
    <div className="flex text-base md:text-sm text-white/75 gap-x-2">
      {icon}
      <div className="flex-1">
        {content && (
          <a
            href={url}
            target="_blank"
            className="block py-1 transition-colors duration-150 hover:text-white hover:underline underline-offset-2 text-pretty md:py-0"
          >
            {content}
          </a>
        )}
        {label && (
          <a href={url} target="_blank" className="py-1 text-pretty md:py-0">
            {label}
          </a>
        )}
      </div>
    </div>
  );
}

function FooterLink({ href, className = "", children }) {
  return (
    <NavigationLink
      className={`flex gap-2 w-fit text-sm underline transition-colors duration-150 text-white/75 underline-offset-2 hover:text-white ${
        className ? className : ""
      }`}
      href={href}
    >
      {children}
    </NavigationLink>
  );
}

function SocialLink({ href, className = "", children }) {
  return (
    <a
      className={`flex gap-2 w-fit text-sm underline transition-colors duration-150 text-white/75 underline-offset-2 hover:text-white ${
        className ? className : ""
      }`}
      href={href}
      target="_blank"
    >
      {children}
    </a>
  );
}

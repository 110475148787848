interface Props {
  data?: { success: boolean; message: string } | null;
  className?: string;
}

export default function Notification({ data, className }: Props) {
  if (!data?.message) return null;

  return (
    <div
      className={`p-3 text-white text-sm text-pretty rounded-sm ${
        data.success ? "bg-green-600" : "bg-red-600"
      } ${className}`}
    >
      {data.message}
    </div>
  );
}

import useWeb from "@/scripts/Hooks/useWeb";
import asset from "@/scripts/Utils/assets";
import l from "@/scripts/Utils/locale";
import uri from "@/scripts/Utils/uri";
import LanguageSelector from "@/views/Components/LanguageSelector";
import LanguageSelectorMini from "@/views/Components/LanguageSelectorMini";
import MenuDropdownLink from "@/views/Components/MenuDropdownLink";
import MenuLink from "@/views/Components/MenuLink";
import NavigationLink from "@/views/Components/NavigationLink";
import { usePage } from "@inertiajs/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DropdownLink from "../../Components/DropdownLink";

export default function Header() {
  const page = usePage();
  const { user, navigation, web } = page.props;
  const {
    state: { isMenuOpen },
    setState,
  } = useWeb();
  const { t } = useTranslation();

  function toggle() {
    setState((old) => {
      if (!old.isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }

      window.scrollTo({ top: 0, behavior: "smooth" });

      return { ...old, isMenuOpen: !old.isMenuOpen };
    });
  }

  function close() {
    setState((old) => {
      document.body.style.overflow = "auto";
      window.scrollTo({ top: 0, behavior: "smooth" });

      return { ...old, isMenuOpen: false };
    });
  }

  function isActive(href: string): boolean {
    let url = page.url.includes("?")
      ? page.url.slice(0, page.url.indexOf("?"))
      : page.url;
    url = url.endsWith("/") ? url.slice(0, -1) : url;
    return url === (href.endsWith("/") ? href.slice(0, -1) : href);
  }

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (isMenuOpen) {
      close();
    }
  }, [route().current()]);

  return (
    <>
      <header
        className={`relative h-20 md:h-24 text-white z-50 transition-colors ease-linear duration-300 ${
          isMenuOpen ? "bg-background" : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl w-content h-full mx-auto flex items-center justify-between gap-2">
          <div className="flex-1 flex items-center gap-0 sm:gap-4">
            <MenuLink
              className="md:ring-1 md:ring-current md:backdrop:px-4 md:text-sm w-9 md:w-fit min-h-9"
              href={l(navigation?.cta_button["url"])}
              aria-label={l(navigation?.cta_button["label"])}
            >
              <span className="hidden md:inline">
                {l(navigation?.cta_button["label"])}
              </span>
              <svg
                width="22"
                height="22"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="md:hidden"
              >
                <path
                  d="M9.65391 19.0148C7.12445 19.0148 4.98239 18.1375 3.22773 16.3829C1.47304 14.6282 0.595703 12.4861 0.595703 9.95664C0.595703 7.42718 1.47304 5.28511 3.22773 3.53042C4.98239 1.77577 7.12445 0.898438 9.65391 0.898438C12.1834 0.898438 14.3254 1.77577 16.0801 3.53042C17.8348 5.28511 18.7121 7.42718 18.7121 9.95664C18.7121 11.0144 18.5346 12.0247 18.1795 12.9875C17.8243 13.9502 17.3505 14.7877 16.758 15.4998L25.2808 24.0226C25.4859 24.2277 25.5908 24.4855 25.5955 24.796C25.6003 25.1064 25.4954 25.3689 25.2808 25.5835C25.0662 25.7981 24.8061 25.9054 24.5003 25.9054C24.1946 25.9054 23.9345 25.7981 23.7199 25.5835L15.1971 17.0608C14.4565 17.6723 13.6048 18.1508 12.642 18.4964C11.6792 18.842 10.6832 19.0148 9.65391 19.0148ZM9.65391 16.7931C11.5624 16.7931 13.1789 16.1308 14.5035 14.8062C15.8281 13.4817 16.4903 11.8652 16.4903 9.95664C16.4903 8.04813 15.8281 6.4316 14.5035 5.10704C13.1789 3.78248 11.5624 3.1202 9.65391 3.1202C7.7454 3.1202 6.12887 3.78248 4.80431 5.10704C3.47977 6.4316 2.8175 8.04813 2.8175 9.95664C2.8175 11.8652 3.47977 13.4817 4.80431 14.8062C6.12887 16.1308 7.7454 16.7931 9.65391 16.7931Z"
                  fill="currentColor"
                />
              </svg>
            </MenuLink>
            <MenuLink
              className="hidden lg:flex items-center gap-2 h-9"
              href={l(navigation?.contact_button["url"])}
              aria-label={l(navigation?.contact_button["label"])}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="size-6"
              >
                <path
                  d="M16.625 17.5C14.8889 17.5 13.1736 17.1215 11.4792 16.3646C9.78472 15.6076 8.24306 14.5347 6.85417 13.1458C5.46528 11.7569 4.39236 10.2153 3.63542 8.52083C2.87847 6.82639 2.5 5.11111 2.5 3.375C2.5 3.125 2.58333 2.91667 2.75 2.75C2.91667 2.58333 3.125 2.5 3.375 2.5H6.75C6.94444 2.5 7.11806 2.56597 7.27083 2.69792C7.42361 2.82986 7.51389 2.98611 7.54167 3.16667L8.08333 6.08333C8.11111 6.30556 8.10417 6.49306 8.0625 6.64583C8.02083 6.79861 7.94444 6.93056 7.83333 7.04167L5.8125 9.08333C6.09028 9.59722 6.42014 10.0938 6.80208 10.5729C7.18403 11.0521 7.60417 11.5139 8.0625 11.9583C8.49306 12.3889 8.94444 12.7882 9.41667 13.1562C9.88889 13.5243 10.3889 13.8611 10.9167 14.1667L12.875 12.2083C13 12.0833 13.1632 11.9896 13.3646 11.9271C13.566 11.8646 13.7639 11.8472 13.9583 11.875L16.8333 12.4583C17.0278 12.5139 17.1875 12.6146 17.3125 12.7604C17.4375 12.9062 17.5 13.0694 17.5 13.25V16.625C17.5 16.875 17.4167 17.0833 17.25 17.25C17.0833 17.4167 16.875 17.5 16.625 17.5ZM5.02083 7.5L6.39583 6.125L6.04167 4.16667H4.1875C4.25694 4.73611 4.35417 5.29861 4.47917 5.85417C4.60417 6.40972 4.78472 6.95833 5.02083 7.5ZM12.4792 14.9583C13.0208 15.1944 13.5729 15.3819 14.1354 15.5208C14.6979 15.6597 15.2639 15.75 15.8333 15.7917V13.9583L13.875 13.5625L12.4792 14.9583Z"
                  fill="currentColor"
                />
              </svg>
              {l(navigation?.contact_button["label"])}
            </MenuLink>
            <MenuLink
              className="flex lg:hidden p-0 items-center size-9"
              href={l(navigation?.contact_button["url"])}
              aria-label={l(navigation?.contact_button["label"])}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="size-6"
              >
                <path
                  d="M16.625 17.5C14.8889 17.5 13.1736 17.1215 11.4792 16.3646C9.78472 15.6076 8.24306 14.5347 6.85417 13.1458C5.46528 11.7569 4.39236 10.2153 3.63542 8.52083C2.87847 6.82639 2.5 5.11111 2.5 3.375C2.5 3.125 2.58333 2.91667 2.75 2.75C2.91667 2.58333 3.125 2.5 3.375 2.5H6.75C6.94444 2.5 7.11806 2.56597 7.27083 2.69792C7.42361 2.82986 7.51389 2.98611 7.54167 3.16667L8.08333 6.08333C8.11111 6.30556 8.10417 6.49306 8.0625 6.64583C8.02083 6.79861 7.94444 6.93056 7.83333 7.04167L5.8125 9.08333C6.09028 9.59722 6.42014 10.0938 6.80208 10.5729C7.18403 11.0521 7.60417 11.5139 8.0625 11.9583C8.49306 12.3889 8.94444 12.7882 9.41667 13.1562C9.88889 13.5243 10.3889 13.8611 10.9167 14.1667L12.875 12.2083C13 12.0833 13.1632 11.9896 13.3646 11.9271C13.566 11.8646 13.7639 11.8472 13.9583 11.875L16.8333 12.4583C17.0278 12.5139 17.1875 12.6146 17.3125 12.7604C17.4375 12.9062 17.5 13.0694 17.5 13.25V16.625C17.5 16.875 17.4167 17.0833 17.25 17.25C17.0833 17.4167 16.875 17.5 16.625 17.5ZM5.02083 7.5L6.39583 6.125L6.04167 4.16667H4.1875C4.25694 4.73611 4.35417 5.29861 4.47917 5.85417C4.60417 6.40972 4.78472 6.95833 5.02083 7.5ZM12.4792 14.9583C13.0208 15.1944 13.5729 15.3819 14.1354 15.5208C14.6979 15.6597 15.2639 15.75 15.8333 15.7917V13.9583L13.875 13.5625L12.4792 14.9583Z"
                  fill="currentColor"
                />
              </svg>
            </MenuLink>
            <a
              className="rounded-sm transition-all duration-300 hover:bg-white/20 flex lg:hidden items-center justify-center size-9"
              href={web.contact.whatsapp_url}
              aria-label={"WhatsApp"}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="size-5"
              >
                <path
                  d="M18.096 14.3827C17.7952 14.2305 16.3305 13.5157 16.0567 13.4137C15.783 13.3162 15.5842 13.2652 15.3847 13.566C15.1897 13.8592 14.6152 14.5305 14.4397 14.7262C14.2642 14.922 14.0917 14.937 13.7955 14.8043C13.4947 14.652 12.534 14.3392 11.3932 13.3162C10.5022 12.5235 9.90898 11.547 9.73273 11.2463C9.55723 10.9493 9.71323 10.7812 9.86173 10.6327C9.99823 10.4963 10.1625 10.2893 10.3147 10.1093C10.4595 9.92925 10.506 9.8085 10.6117 9.6135C10.7092 9.40275 10.659 9.2385 10.5847 9.09C10.5105 8.9415 9.91273 7.46925 9.66298 6.88275C9.42448 6.30075 9.17473 6.375 8.99098 6.375C8.81923 6.35925 8.61973 6.35925 8.42098 6.35925C8.22223 6.35925 7.89748 6.4335 7.62373 6.7185C7.34998 7.01925 6.57673 7.73775 6.57673 9.19125C6.57673 10.6485 7.64698 12.0585 7.79548 12.2692C7.94773 12.4642 9.90073 15.4642 12.897 16.7535C13.6117 17.0542 14.1667 17.2342 14.6002 17.3827C15.315 17.6092 15.9675 17.5777 16.4827 17.5035C17.0527 17.4097 18.2482 16.7805 18.4987 16.0778C18.753 15.3705 18.753 14.7847 18.6787 14.652C18.6045 14.5155 18.4095 14.4412 18.1087 14.3085L18.096 14.3827ZM12.6622 21.75H12.6465C10.8727 21.75 9.11923 21.2692 7.58773 20.3708L7.22848 20.1562L3.47848 21.1327L4.48648 17.484L4.24423 17.109C3.25573 15.5347 2.72848 13.7183 2.72848 11.8515C2.72848 6.4065 7.18573 1.965 12.6697 1.965C15.3262 1.965 17.8185 3 19.6935 4.875C21.5685 6.73425 22.6035 9.2265 22.6035 11.8673C22.596 17.3085 18.1425 21.75 12.666 21.75H12.6622ZM21.1192 3.44925C18.8377 1.24575 15.8377 0 12.6465 0C6.06448 0 0.704976 5.33625 0.701226 11.8942C0.701226 13.9882 1.24798 16.0312 2.29498 17.8395L0.599976 24L6.93598 22.3478C8.68198 23.289 10.647 23.793 12.6472 23.7967H12.651C19.2367 23.7967 24.5962 18.4605 24.6 11.898C24.6 8.7225 23.3617 5.73375 21.1042 3.4875L21.1192 3.44925Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <NavigationLink href={uri("home")} aria-label={t("Home")}>
            <img
              className="size-16 object-contain object-center"
              src={asset(web.light_logo)}
              width="64"
              height="64"
              alt={web.title}
            />
          </NavigationLink>
          <div className="flex-1 flex items-center justify-end gap-0 sm:gap-4 md:gap-5">
            <nav className="flex-1 hidden lg:flex items-center justify-end">
              {navigation?.main_menu?.map((item) => {
                if (item.items?.length > 0) {
                  return (
                    <MenuDropdownLink
                      active={isActive(l(item.url))}
                      href={l(item.url)}
                      items={item.items}
                      key={l(item.url)}
                      aria-label={l(item.label)}
                    >
                      {l(item.label)}
                    </MenuDropdownLink>
                  );
                }

                return (
                  <MenuLink
                    active={isActive(l(item.url))}
                    href={l(item.url)}
                    key={l(item.url)}
                    aria-label={l(item.label)}
                  >
                    {l(item.label)}
                  </MenuLink>
                );
              })}
            </nav>

            <LanguageSelectorMini className="z-50" />
            <button
              className="flex items-center justify-center size-9 hover:bg-white/10 rounded-sm"
              onClick={() => toggle()}
              aria-label={t("Dropdown menu")}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.375 22.043V20.1681H25.6249V22.043H4.375ZM4.375 15.9373V14.0623H25.6249V15.9373H4.375ZM4.375 9.83151V7.95654H25.6249V9.83151H4.375Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          className="fixed w-full top-20 md:top-24 bg-background transition-all ease-linear duration-300"
          style={{
            visibility: isMenuOpen ? "visible" : "hidden",
            opacity: isMenuOpen ? "1" : "0",
            height: "calc(100vh - 96px)",
          }}
        >
          <div className="max-w-4xl mx-auto w-content">
            <nav className="grid grid-cols-2 gap-[1px] ring-1 ring-muted rounded-sm overflow-hidden mb-24">
              {navigation?.dropdown_menu?.map((item) => {
                return (
                  <DropdownLink
                    className="text-base md:text-xl"
                    link={{ ...item, label: l(item.label), url: l(item.url) }}
                    key={l(item.url)}
                  />
                );
              })}
            </nav>
            <nav className="grid grid-cols-2 gap-[1px] rounded-sm">
              <DropdownLink
                className="text-base rounded-t-sm"
                link={{
                  url: uri("vehicles.favorites"),
                  icon: (
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-muted"
                    >
                      <path
                        d="M7.99358 13.2564C7.86196 13.2564 7.73526 13.2335 7.61347 13.1878C7.49167 13.1421 7.37607 13.0701 7.26667 12.9718L6.47567 12.2513C5.26028 11.1735 4.19938 10.1314 3.29297 9.12501C2.38656 8.1186 1.93335 7.05471 1.93335 5.93336C1.93335 5.05388 2.2357 4.31735 2.8404 3.72376C3.4451 3.13017 4.19274 2.83337 5.08333 2.83337C5.59871 2.83337 6.11025 2.95175 6.61795 3.18851C7.12564 3.42526 7.58632 3.82783 8 4.39621C8.4359 3.82783 8.90214 3.42526 9.39872 3.18851C9.89531 2.95175 10.4013 2.83337 10.9167 2.83337C11.8073 2.83337 12.5549 3.13017 13.1596 3.72376C13.7643 4.31735 14.0667 5.05388 14.0667 5.93336C14.0667 7.06754 13.6028 8.14104 12.675 9.15386C11.7472 10.1667 10.697 11.197 9.52433 12.2449L8.72692 12.9718C8.61752 13.0701 8.50085 13.1421 8.37692 13.1878C8.25298 13.2335 8.12521 13.2564 7.99358 13.2564Z"
                        fill="currentColor"
                      />
                    </svg>
                  ),
                  label: t("Favorites"),
                }}
              />
              <DropdownLink
                className="text-base rounded-tr-sm"
                link={{
                  url: uri("vehicles.comparator"),
                  icon: (
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-muted"
                    >
                      <path
                        d="M6.52062 10.891H2.16675C2.02487 10.891 1.90607 10.8431 1.81035 10.7474C1.71461 10.6517 1.66675 10.5329 1.66675 10.391C1.66675 10.2491 1.71461 10.1303 1.81035 10.0346C1.90607 9.93889 2.02487 9.89103 2.16675 9.89103H6.52062L4.98213 8.35254C4.88982 8.26023 4.8426 8.14591 4.84047 8.00959C4.83832 7.87327 4.88554 7.7534 4.98213 7.64999C5.08554 7.54658 5.20434 7.49487 5.33853 7.49487C5.47271 7.49487 5.59151 7.54658 5.69493 7.64999L8.01415 9.96922C8.07655 10.0316 8.12057 10.0974 8.1462 10.1667C8.17184 10.2359 8.18467 10.3107 8.18467 10.391C8.18467 10.4714 8.17184 10.5461 8.1462 10.6154C8.12057 10.6846 8.07655 10.7504 8.01415 10.8128L5.68466 13.1423C5.58553 13.2414 5.46951 13.2904 5.3366 13.2891C5.2037 13.2878 5.08554 13.2354 4.98213 13.132C4.88554 13.0286 4.83554 12.9115 4.83213 12.7808C4.82871 12.65 4.87871 12.5329 4.98213 12.4295L6.52062 10.891ZM9.47952 7.10256L11.018 8.64104C11.1103 8.73334 11.1575 8.84765 11.1597 8.98397C11.1618 9.1203 11.1146 9.24016 11.018 9.34358C10.9146 9.447 10.7958 9.49871 10.6616 9.49871C10.5274 9.49871 10.4086 9.447 10.3052 9.34358L7.98598 7.02436C7.92358 6.96196 7.87956 6.89615 7.85393 6.82693C7.82829 6.75769 7.81547 6.6829 7.81547 6.60256C7.81547 6.52222 7.82829 6.44744 7.85393 6.37821C7.87956 6.30897 7.92358 6.24316 7.98598 6.18077L10.3155 3.85129C10.4146 3.75215 10.5306 3.70321 10.6635 3.70449C10.7964 3.70578 10.9146 3.75813 11.018 3.86154C11.1146 3.96496 11.1646 4.08206 11.168 4.21283C11.1714 4.34359 11.1214 4.46068 11.018 4.56409L9.47952 6.10258H13.8334C13.9753 6.10258 14.0941 6.15044 14.1898 6.24616C14.2855 6.34188 14.3334 6.46068 14.3334 6.60256C14.3334 6.74444 14.2855 6.86324 14.1898 6.95896C14.0941 7.05469 13.9753 7.10256 13.8334 7.10256H9.47952Z"
                        fill="currentColor"
                      />
                    </svg>
                  ),
                  label: t("Comparator"),
                }}
              />
              {!user && (
                <DropdownLink
                  className="text-base rounded-bl-sm"
                  link={{
                    url: route("filament.admin.auth.login"),
                    icon: (
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-muted"
                      >
                        <path
                          d="M8.42738 13.7667C8.3044 13.7667 8.20143 13.7256 8.11848 13.6433C8.03553 13.561 7.99405 13.4589 7.99405 13.3369C7.99405 13.2149 8.03553 13.1116 8.11848 13.027C8.20143 12.9424 8.3044 12.9 8.42738 12.9H12.1953C12.2466 12.9 12.2936 12.8787 12.3364 12.8359C12.3791 12.7932 12.4005 12.7462 12.4005 12.6949V4.3052C12.4005 4.25391 12.3791 4.20689 12.3364 4.16415C12.2936 4.12142 12.2466 4.10005 12.1953 4.10005H8.42738C8.3044 4.10005 8.20143 4.05891 8.11848 3.97663C8.03553 3.89436 7.99405 3.79223 7.99405 3.67023C7.99405 3.54824 8.03553 3.44494 8.11848 3.36033C8.20143 3.27571 8.3044 3.2334 8.42738 3.2334H12.1953C12.4954 3.2334 12.7491 3.33701 12.9563 3.54423C13.1635 3.75145 13.2671 4.00511 13.2671 4.3052V12.6949C13.2671 12.995 13.1635 13.2486 12.9563 13.4559C12.7491 13.6631 12.4954 13.7667 12.1953 13.7667H8.42738ZM7.87102 8.93337H3.17295C3.05013 8.93337 2.94731 8.89223 2.86448 8.80997C2.78165 8.72769 2.74023 8.62555 2.74023 8.50357C2.74023 8.38157 2.78165 8.27826 2.86448 8.19365C2.94731 8.10904 3.05013 8.06673 3.17295 8.06673H7.87102L6.90675 7.10135C6.81463 7.00904 6.7675 6.90437 6.76537 6.78733C6.76323 6.67031 6.81036 6.56604 6.90675 6.47453C7.00315 6.38302 7.10612 6.33833 7.21567 6.34047C7.32521 6.3426 7.42613 6.38982 7.51843 6.48213L9.16457 8.12827C9.27397 8.23362 9.32867 8.35654 9.32867 8.49702C9.32867 8.63749 9.27397 8.76243 9.16457 8.87183L7.51843 10.518C7.4304 10.606 7.33211 10.6494 7.22357 10.6481C7.11503 10.6468 7.01196 10.6 6.91433 10.5077C6.82307 10.4154 6.77637 10.3133 6.77423 10.2013C6.77209 10.0893 6.81931 9.98508 6.91588 9.8885L7.87102 8.93337Z"
                          fill="currentColor"
                        />
                      </svg>
                    ),
                    label: t("Log in"),
                    isExternal: true,
                    openInNewTab: false,
                  }}
                />
              )}
              {user && (
                <DropdownLink
                  className="text-base rounded-bl-sm"
                  link={{
                    url: route("filament.admin.pages.dashboard"),
                    icon: (
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-muted"
                      >
                        <path
                          d="M7.99985 8.29491C7.42294 8.29491 6.93427 8.09469 6.53384 7.69427C6.13341 7.29385 5.9332 6.80518 5.9332 6.22827C5.9332 5.65135 6.13341 5.16268 6.53384 4.76225C6.93427 4.36183 7.42294 4.16162 7.99985 4.16162C8.57676 4.16162 9.06544 4.36183 9.46587 4.76225C9.86629 5.16268 10.0665 5.65135 10.0665 6.22827C10.0665 6.80518 9.86629 7.29385 9.46587 7.69427C9.06544 8.09469 8.57676 8.29491 7.99985 8.29491ZM3.5332 11.959V11.6231C3.5332 11.4189 3.5941 11.2221 3.7159 11.0328C3.83769 10.8434 4.00799 10.6796 4.22679 10.5411C4.80799 10.2069 5.41995 9.9507 6.06269 9.7725C6.70542 9.5943 7.35114 9.5052 7.99985 9.5052C8.64856 9.5052 9.29429 9.5943 9.93702 9.7725C10.5798 9.9507 11.1917 10.2069 11.7729 10.5411C11.9917 10.6684 12.162 10.8296 12.2838 11.0244C12.4056 11.2193 12.4665 11.4189 12.4665 11.6231V11.959C12.4665 12.2069 12.3819 12.4154 12.2127 12.5846C12.0434 12.7539 11.8349 12.8385 11.587 12.8385H4.41267C4.16481 12.8385 3.95628 12.7539 3.78705 12.5846C3.61782 12.4154 3.5332 12.2069 3.5332 11.959Z"
                          fill="currentColor"
                        />
                      </svg>
                    ),
                    label: t("Dashboard"),
                    isExternal: true,
                    openInNewTab: false,
                  }}
                />
              )}
              <LanguageSelector className="rounded-br-sm" />
            </nav>
            <p className="py-4 text-center text-sm text-muted">
              {new Date().getFullYear()} © {web.title}
            </p>
          </div>
        </div>
      </header>
    </>
  );
}

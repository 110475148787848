import { WebProvider } from "@/scripts/Contexts/WebContext";
import Footer from "@/views/Layouts/Partials/Footer";
import Header from "@/views/Layouts/Partials/Header";
import { usePage } from "@inertiajs/react";
import Newsletter from "../Components/Sections/Newsletter";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Guest({ title, children }: Props) {
  const {
    props: { web, locale },
  } = usePage();

  return (
    <WebProvider locale={locale}>
      <Header />
      <main className="bg-foreground py-[1px]">
        {children}
        {web.newsletter.enabled && <Newsletter {...web.newsletter} />}
      </main>
      <Footer />
    </WebProvider>
  );
}

import { usePage } from "@inertiajs/react";

export default function uri(
  routeName: string,
  params: { [key: string]: any } = {},
  locale: string | null = null
) {
  if (!locale) {
    const { locale: upLocale } = usePage().props;
    locale = upLocale;
  }

  const uri = route(routeName + (locale != "en" ? "." + locale : ""), params);

  return uri;
}

import NavigationLink from "./NavigationLink";

interface Props {
  link: {
    url: string;
    icon?: JSX.Element | null;
    label: string;
    isExternal?: boolean;
    isFullWidth?: boolean;
    openInNewTab?: boolean;
  };
  className?: string;
}

export default function DropdownLink({
  link = {
    url: "",
    icon: null,
    label: "",
    isExternal: false,
    isFullWidth: false,
    openInNewTab: true,
  },
  className = "",
}: Props) {
  const Component: any = link.isExternal ? "a" : NavigationLink;

  return (
    <Component
      className={`flex items-center justify-between px-5 py-3 ring-1 ring-muted text-white hover:bg-white/10 transition-colors duration-150 ${
        link.isFullWidth ? "col-span-full" : ""
      } ${className}`}
      href={link.url}
      target={
        link.isExternal && (link.openInNewTab ?? true) ? "_blank" : undefined
      }
      aria-label={link.label}
      key={link.url}
    >
      <p className="flex items-center gap-2 text-balance">
        {link.icon}
        {link.label}
      </p>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-muted"
      >
        <path
          d="M11.464 10.5L5.43676 4.47273C5.28505 4.32102 5.20785 4.13433 5.20517 3.91265C5.20251 3.69095 5.27703 3.50426 5.42873 3.35256C5.58044 3.20085 5.76714 3.125 5.98882 3.125C6.2105 3.125 6.39719 3.20085 6.5489 3.35256L12.762 9.55769C12.8988 9.69444 12.9966 9.8424 13.0553 10.0016C13.1141 10.1608 13.1434 10.3269 13.1434 10.5C13.1434 10.673 13.1141 10.8392 13.0553 10.9984C12.9966 11.1576 12.8988 11.3055 12.762 11.4423L6.55692 17.6474C6.40521 17.7991 6.21718 17.8763 5.99282 17.879C5.76847 17.8816 5.58044 17.8071 5.42873 17.6554C5.27703 17.5037 5.20117 17.317 5.20117 17.0953C5.20117 16.8737 5.27703 16.687 5.42873 16.5353L11.464 10.5Z"
          fill="currentColor"
        />
      </svg>
    </Component>
  );
}

import NavigationLink from "./NavigationLink";

interface Props {
  active?: boolean;
  href: string;
  className?: string;
  "aria-label"?: string;
  children: React.ReactNode;
}

export default function MenuLink({
  active = false,
  href,
  className,
  "aria-label": ariaLabel,
  children,
}: Props) {
  return (
    <NavigationLink
      href={href}
      className={`relative px-2 py-2 text-sm text-balance text-center rounded-sm transition-all duration-300 hover:bg-white/20 ${className} ${
        active ? "bg-white/20" : ""
      }`}
      aria-label={ariaLabel}
    >
      {children}
    </NavigationLink>
  );
}

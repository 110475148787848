import { usePage } from "@inertiajs/react";

export default function l(
  translatable: { [name: string]: any } | string | null | undefined,
  options: {
    locale?: string | null;
    otherOnEmpty?: boolean;
  } = { locale: null, otherOnEmpty: false }
): string {
  if (!translatable) return "";
  if (typeof translatable === "string") return translatable;

  if (!options) options = { locale: null, otherOnEmpty: false };

  if (!options.locale) {
    const { locale: localePage } = usePage().props;
    options.locale = localePage;
  }

  if (options.locale) {
    if (options.locale in translatable) {
      if (typeof translatable[options.locale] === "string") {
        return translatable[options.locale];
      }
    }
  }

  if (options.otherOnEmpty) {
    for (const key in translatable) {
      if (typeof translatable[key] === "string") {
        return translatable[key];
      }
    }
  }

  return "";
}

import l from "@/scripts/Utils/locale";
import NavigationLink from "./NavigationLink";

interface Props {
  active?: boolean;
  href: string;
  items?: any[];
  className?: string;
  "aria-label"?: string;
  children: React.ReactNode;
}

export default function MenuDropdownLink({
  active = false,
  href,
  items = [],
  className,
  "aria-label": ariaLabel,
  children,
}: Props) {
  return (
    <div className="group/dropdown relative">
      <NavigationLink
        href={href}
        className={`block px-2 py-2 text-sm text-balance text-center rounded-sm transition-all duration-300 hover:bg-white/20 ${className} ${
          active ? "bg-white/20" : ""
        }`}
        aria-label={ariaLabel}
      >
        {children}
      </NavigationLink>
      <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-36 pt-2 hidden z-50 group-hover/dropdown:block">
        <div className="py-1 bg-background text-white ring-1 ring-muted rounded-sm flex flex-col">
          {items.map((item, index) => (
            <NavigationLink
              className="px-3 py-2 hover:bg-white/20 text-sm"
              href={l(item.url)}
              key={index}
              aria-label={l(item.label)}
            >
              {l(item.label)}
            </NavigationLink>
          ))}
        </div>
      </div>
    </div>
  );
}

import useWebGlobal from "@/scripts/Hooks/useWebGlobal";
import { createContext } from "react";
import { useTranslation } from "react-i18next";

const WebContext = createContext<any>(null);
export default WebContext;
export function WebProvider({ locale, children }) {
  const { t } = useTranslation();
  const web = useWebGlobal(t, locale);
  return <WebContext.Provider value={web}>{children}</WebContext.Provider>;
}

import useWeb from "@/scripts/Hooks/useWeb";
import { Link } from "@inertiajs/react";

interface Props {
  href: string;
  children: React.ReactNode;
  onClick?: (e) => void;
  className?: string;
  "aria-label"?: string;
}
export default function NavigationLink({
  href,
  children,
  onClick,
  className,
  "aria-label": ariaLabel,
}: Props) {
  const {
    actions: { closeMenu },
  } = useWeb();

  function handleClick(e) {
    closeMenu();
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <Link
      href={href}
      className={className}
      onClick={handleClick}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
}

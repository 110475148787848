import axios from "axios";

export default async function fetchApi<T>({
  url,
  baseURL = "/api",
  method = "GET",
  query,
  body,
  headers,
}: {
  url: string;
  baseURL?: string;
  method: string;
  query?: any;
  body?: any;
  locale?: string;
  headers?: any;
}): Promise<T> {
  const response = await axios<T>({
    url,
    baseURL,
    method,
    params: query,
    data: body,
    headers: {
      ...headers,
    },
  });

  return response.data;
}

import { router } from "@inertiajs/react";
import { useState } from "react";
import { toast } from "sonner";
import { getCookie, setCookie } from "../Utils/cookies";
import uri from "../Utils/uri";

export interface WebGlobal {
  state: {
    isMenuOpen: boolean;
    favorites: string[];
    comparisons: string[];
    cookiesAccepted: boolean;
    showCookiesAlert: boolean;
  };
  setState: (state: any) => void;
  actions: {
    openMenu: () => void;
    closeMenu: () => void;
    toggleMenu: () => void;
    acceptCookies: () => void;
    declineCookies: () => void;
    favorites: {
      all: () => string[];
      set: (ids: (string | number)[]) => void;
      is: (id: string | number) => boolean;
      add: (id: string | number) => void;
      addList: (ids: (string | number)[]) => void;
      remove: (id: string | number) => void;
      toggle: (id: string | number) => void;
    };
    comparisons: {
      all: () => string[];
      set: (ids: (string | number)[]) => void;
      is: (id: string | number) => boolean;
      add: (id: string | number) => void;
      addList: (ids: (string | number)[]) => void;
      remove: (id: string | number) => void;
      toggle: (id: string | number) => void;
    };
    [key: string]: any;
  };
}

export default function useWebGlobal(
  t: (key: string) => string,
  locale: string = "en"
): WebGlobal {
  const [state, setState] = useState({
    isMenuOpen: false,
    favorites: getFavorites(),
    comparisons: getComparisons(),
    cookiesAccepted: getCookie("cookie_consent") === "true",
    showCookiesAlert: getCookie("cookie_consent") === null,
  });

  function openMenu() {
    document.body.style.overflow = "hidden";
    setState((prev) => ({ ...prev, isMenuOpen: true }));
  }

  function closeMenu() {
    document.body.style.overflow = "auto";
    setState((prev) => ({ ...prev, isMenuOpen: false }));
  }

  function toggleMenu() {
    setState((prev) => {
      if (!prev.isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      return { ...prev, isMenuOpen: !prev.isMenuOpen };
    });
  }

  function acceptCookies() {
    setCookie("cookie_consent", true, 365);
    setState((prev) => ({
      ...prev,
      cookiesAccepted: true,
      showCookiesAlert: false,
    }));
    window.location.reload();
  }

  function declineCookies() {
    setCookie("cookie_consent", false, 365);
    setState((prev) => ({
      ...prev,
      cookiesAccepted: false,
      showCookiesAlert: false,
    }));
  }

  // Comparisons
  function getComparisons() {
    return JSON.parse(localStorage.getItem("comparisons") || "[]");
  }

  function setComparisons(ids: (string | number)[]) {
    setState((prev) => ({
      ...prev,
      comparisons: ids.map((i) => i.toString()),
    }));
    localStorage.setItem(
      "comparisons",
      JSON.stringify(ids.map((i) => i.toString()))
    );
  }

  function isCompared(id: string | number): boolean {
    return state.comparisons.includes(id.toString());
  }

  function addCompared(id: string | number): void {
    setState((prev) => {
      localStorage.setItem(
        "comparisons",
        JSON.stringify([...prev.comparisons, id.toString()])
      );
      return { ...prev, comparisons: [...prev.comparisons, id.toString()] };
    });
  }

  function addListToCompare(ids: (string | number)[]): void {
    setState((prev) => {
      const newList = new Set([
        ...prev.comparisons,
        ...ids.map((i) => i.toString()),
      ]);
      localStorage.setItem("comparisons", JSON.stringify(Array.from(newList)));
      return {
        ...prev,
        comparisons: Array.from(newList),
      };
    });
  }

  function removeCompared(id: string | number): void {
    setState((prev) => {
      localStorage.setItem(
        "comparisons",
        JSON.stringify(prev.comparisons.filter((i) => i !== id.toString()))
      );
      return {
        ...prev,
        comparisons: prev.comparisons.filter((i) => i !== id.toString()),
      };
    });
  }

  function toggleCompared(id: string | number): void {
    setState((prev) => {
      if (prev.comparisons.includes(id.toString())) {
        localStorage.setItem(
          "comparisons",
          JSON.stringify(prev.comparisons.filter((i) => i !== id.toString()))
        );
        toast(t("Removed from comparator"), {
          action: {
            label: t("Go to comparator"),
            onClick: () => {
              router.visit(uri("vehicles.comparator", {}, locale));
            },
          },
        });
        return {
          ...prev,
          comparisons: prev.comparisons.filter((i) => i !== id.toString()),
        };
      } else {
        localStorage.setItem(
          "comparisons",
          JSON.stringify([...prev.comparisons, id.toString()])
        );
        toast(t("Added to comparator"), {
          action: {
            label: t("Go to comparator"),
            onClick: () => {
              router.visit(uri("vehicles.comparator", {}, locale));
            },
          },
        });
        return { ...prev, comparisons: [...prev.comparisons, id.toString()] };
      }
    });
  }

  // Favorites

  function getFavorites() {
    return JSON.parse(localStorage.getItem("favorites") || "[]");
  }

  function setFavorites(ids: (string | number)[]) {
    setState((prev) => ({ ...prev, favorites: ids.map((i) => i.toString()) }));
    localStorage.setItem(
      "favorites",
      JSON.stringify(ids.map((i) => i.toString()))
    );
  }

  function isFavorite(id: string | number): boolean {
    return state.favorites.includes(id.toString());
  }

  function addFavorite(id: string | number): void {
    setState((prev) => {
      localStorage.setItem(
        "favorites",
        JSON.stringify([...prev.favorites, id.toString()])
      );
      return { ...prev, favorites: [...prev.favorites, id.toString()] };
    });
  }

  function addListToFavorites(ids: (string | number)[]): void {
    setState((prev) => {
      const newList = new Set([
        ...prev.favorites,
        ...ids.map((i) => i.toString()),
      ]);
      localStorage.setItem("favorites", JSON.stringify(Array.from(newList)));
      return {
        ...prev,
        favorites: Array.from(newList),
      };
    });
  }

  function removeFavorite(id: string | number): void {
    setState((prev) => {
      localStorage.setItem(
        "favorites",
        JSON.stringify(prev.favorites.filter((i) => i !== id.toString()))
      );
      return {
        ...prev,
        favorites: prev.favorites.filter((i) => i !== id.toString()),
      };
    });
  }

  function toggleFavorite(id: string | number): void {
    setState((prev) => {
      if (prev.favorites.includes(id.toString())) {
        localStorage.setItem(
          "favorites",
          JSON.stringify(prev.favorites.filter((i) => i !== id.toString()))
        );
        toast(t("Removed from favorites"), {
          action: {
            label: t("View favorites"),
            onClick: () => {
              router.visit(uri("vehicles.favorites", {}, locale));
            },
          },
        });
        return {
          ...prev,
          favorites: prev.favorites.filter((i) => i !== id.toString()),
        };
      } else {
        localStorage.setItem(
          "favorites",
          JSON.stringify([...prev.favorites, id.toString()])
        );
        toast(t("Added to favorites"), {
          action: {
            label: t("View favorites"),
            onClick: () => {
              router.visit(uri("vehicles.favorites", {}, locale));
            },
          },
        });
        return { ...prev, favorites: [...prev.favorites, id.toString()] };
      }
    });
  }

  return {
    state,
    setState,
    actions: {
      acceptCookies,
      declineCookies,
      openMenu,
      closeMenu,
      toggleMenu,
      favorites: {
        all: getFavorites,
        set: setFavorites,
        is: isFavorite,
        add: addFavorite,
        addList: addListToFavorites,
        remove: removeFavorite,
        toggle: toggleFavorite,
      },
      comparisons: {
        all: getComparisons,
        set: setComparisons,
        is: isCompared,
        add: addCompared,
        addList: addListToCompare,
        remove: removeCompared,
        toggle: toggleCompared,
      },
    },
  };
}

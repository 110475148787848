import fetchApi from "@/scripts/Actions";
import asset from "@/scripts/Utils/assets";
import l from "@/scripts/Utils/locale";
import { useForm } from "@inertiajs/react";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import Input from "../Inputs/Input";
import Notification from "../Inputs/Notification";

interface Props {
  title?: App.Data.TranslateData;
  content?: App.Data.TranslateData;
  image?: string | null;
}

export default function Newsletter({ title, content, image }: Props) {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { data, setData, errors, setError, clearErrors, reset } = useForm(
    "newsletter",
    {
      email: "",
      captcha: "",
    }
  );

  const [notification, setNotification] = useState<any>(null);

  async function submit(e) {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not found");
      return;
    }

    executeRecaptcha("newsletter").then((token) => {
      sendForm(token);
    });
  }

  async function sendForm(token: string | null) {
    setNotification(null);
    const response = await fetchApi<{
      success: boolean;
      message?: string;
      errors?: any;
    }>({
      url: "newsletter",
      method: "post",
      body: { ...data, captcha: token },
    });
    setNotification({
      ...response,
      message: response.message ? t(response.message) : undefined,
    });
    clearErrors();

    if (response.errors) {
      for (const key in response.errors) {
        setError(key as "email", response.errors[key]);
      }
    } else {
      reset();
    }
  }

  return (
    <section className="py-16 bg-white">
      <div className="grid md:grid-cols-2 gap-8 items-center place-items-center max-w-7xl w-content mx-auto">
        <form className="max-w-md" onSubmit={submit}>
          <h2 className="text-2xl font-bold font-title text-balance">
            {l(title) || "Newsletter"}
          </h2>
          {l(content) && (
            <div
              className="mt-4 text-muted text-sm text-pretty formatted"
              dangerouslySetInnerHTML={{ __html: l(content) }}
            ></div>
          )}
          <div className="mt-4">
            <Notification data={notification} className="mb-4" />
            <div className="flex items-start gap-2">
              <Input
                name="email"
                placeholder="Email"
                type="email"
                value={data.email}
                error={errors.email}
                onChange={(e) => setData("email", e.target.value)}
                className="flex-1"
              />
              <button
                className="block px-3 py-2 h-[42px] bg-black text-white rounded-sm"
                type="submit"
              >
                {t("Send")}
              </button>
            </div>
          </div>
        </form>
        {image && (
          <img
            className="rounded-sm object-cover w-full h-auto"
            src={asset(image)}
            alt={l(title) || "Newsletter"}
            loading="lazy"
            width={640}
            height={420}
          />
        )}
      </div>
    </section>
  );
}
